import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
import requests from '../../../requests'
import { WABA_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  wabaList: [],
  phoneNumberList: [],
})

export const actions = {
  getWabaList({ commit }) {
    const params = {
      business_id: process.env.WA_BUSINESS_ID,
    }
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(WABA_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('SET_WABA_LIST', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
        }
      )
  },
  getPhoneNumberListById({ commit }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(
        `${WABA_URL}/${params.waba_id}/phone_number`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('SET_PHONE_NUMBER_LIST', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
        }
      )
  },
}

export const mutations = {
  updateField,
  SET_WABA_LIST(state, payload) {
    state.wabaList = _.sortBy(payload, 'name')
  },
  SET_PHONE_NUMBER_LIST(state, payload) {
    state.phoneNumberList = payload
  },
}

export const getters = {
  getField,
}
