import { getField, updateField } from 'vuex-map-fields'
import requests from '~/requests'
import {
  TOKOPEDIA_PRODUCTS,
  TOKOPEDIA_ORDERS,
  SHOPEE_URL,
} from '@/assets/variables/endpoints'

export const state = () => ({
  shopeeProducts: [],
  shopeeOrders: [],
  selectedProduct: [],
  selectedOrder: [],
  isShowAttachedProduct: false,
  isShowAttachedInvoice: false,
})

export const getters = {
  getField,
}

export const actions = {
  retrieveShopeeProductsInfinite({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${SHOPEE_URL}/items`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('PUSH_SHOPEE_PRODUCTS', res?.data?.response)
            resolve(res)
          },
          (err) => {
            if (err?.error.messages?.[0]) {
              commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                { title: err?.error.messages?.[0], variant: 'error' },
                { root: true }
              )
            }
            reject(err)
          }
        )
    })
  },
  retrieveShopeeOrdersInfinite({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${SHOPEE_URL}/orders`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('PUSH_SHOPEE_ORDERS', res?.data?.response)
            resolve(res)
          },
          (err) => {
            if (err?.error.messages?.[0]) {
              commit(
                'layouts/DISPLAY_TOAST_PIXEL',
                { title: err?.error.messages?.[0], variant: 'error' },
                { root: true }
              )
            }
            reject(err)
          }
        )
    })
  },
  getTokopediaProducts({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${TOKOPEDIA_PRODUCTS}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_TOKOPEDIA_PRODUCTS', res)
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getTokopediaOrders({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${TOKOPEDIA_ORDERS}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_TOKOPEDIA_ORDERS', res)
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getShopeeOrders({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${SHOPEE_URL}/orders`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_SHOPEE_ORDERS', res)
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_SHOPEE_PRODUCTS(state, payload) {
    state.shopeeProducts = payload.data.response
  },
  UPDATE_SHOPEE_ORDERS(state, payload) {
    state.shopeeOrders = payload.data.response
  },
  PUSH_SHOPEE_PRODUCTS(state, payload) {
    state.shopeeProducts.push(...payload)
  },
  PUSH_SHOPEE_ORDERS(state, payload) {
    state.shopeeOrders.push(...payload)
  },
  UPDATE_SELECTED_PRODUCTS(state, payload) {
    state.selectedProduct = payload
  },
  UPDATE_SELECTED_ORDER(state, payload) {
    state.selectedOrder = payload
  },
  CLEAR_SELECTED_PRODUCTS(state) {
    state.selectedProduct = []
  },
  CLEAR_SELECTED_ORDERS(state) {
    state.selectedOrder = []
  },
  REMOVE_SELECTED_PRODUCTS(state, payload) {
    state.selectedProduct.splice(payload, 1)
    if (!state.selectedProduct.length) {
      state.isShowAttachedProduct = false
    }
  },
  REMOVE_SELECTED_ORDER(state, payload) {
    state.selectedOrder.splice(payload, 1)
    if (!state.selectedOrder.length) {
      state.isShowAttachedInvoice = false
    }
  },
  UPDATE_TOKOPEDIA_ORDERS(state, payload) {
    state.tokpedOrders = payload.data
  },
  UPDATE_IS_SHOW_ATTACHED_PRODUCT(state, payload) {
    state.isShowAttachedProduct = payload
  },
  UPDATE_IS_SHOW_ATTACHED_INVOICE(state, payload) {
    state.isShowAttachedInvoice = payload
  },
  CLEAR_SHOPEE_ORDERS_AND_PRODUCTS(state) {
    state.shopeeOrders = []
    state.shopeeProducts = []
  },
}
