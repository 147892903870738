import { INTEGRATION_URL } from '~/assets/variables/endpoints'
import { validTokenCookie } from '~/utils/general'
import insertOrganizationIdToUrl from '~/utils/insertOrganizationIdToUrl'

export const actions = {
  async fetchAuthorizeLink(_context, params) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      const payload = { ...params }
      const {
        data: { data },
      } = await this.$axios.post(
        `${insertOrganizationIdToUrl(
          INTEGRATION_URL,
          this.$auth.user.organization_id
        )}/shopee/authorize`,
        payload,
        additional
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async fetchReauthorizeLink(_context, store) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      const payload = {}
      const {
        data: { data },
      } = await this.$axios.post(
        `${insertOrganizationIdToUrl(
          INTEGRATION_URL,
          this.$auth.user.organization_id
        )}/shopee/${store.id}/reauthorize`,
        payload,
        additional
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async fetchDisconnectStore(_context, store) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      const payload = {}
      const res = await this.$axios.post(
        `${insertOrganizationIdToUrl(
          INTEGRATION_URL,
          this.$auth.user.organization_id
        )}/shopee/${store.id}/disconnect`,
        payload,
        additional
      )
      return res.data
    } catch (err) {
      throw err
    }
  },
  async fetchReconnectStore(_context, _store) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      const res = await this.$axios.get(
        `${insertOrganizationIdToUrl(
          INTEGRATION_URL,
          this.$auth.user.organization_id
        )}`,
        additional
      )
      return res.data
    } catch (err) {
      throw err
    }
  },
  async fetchRemoveStore(_context, store) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      const res = await this.$axios.delete(
        `${insertOrganizationIdToUrl(
          INTEGRATION_URL,
          this.$auth.user.organization_id
        )}/shopee/${store.id}`,
        additional
      )
      return res.data
    } catch (err) {
      throw err
    }
  },
}
