import { updateField } from 'vuex-map-fields'
import requests from '../../../requests'
import {
  DIVISION_URL,
  WHATSAPP_URL,
  REPORTS_URL,
} from '~/assets/variables/endpoints'

export const state = () => ({
  divisionList: [],
  isLoadingTags: false,
  tag: {
    list: [],
  },
  channelByDivision: [],
  slaDataTables: {
    response: [],
    pagination: '',
  },
  conversationSummaryData: {
    unassigned_conversation: '',
    assigned_conversation: '',
    resolved_conversation: '',
    average_first_response_time: '',
    average_resolution_time: '',
    average_response_time: '',
  },
  isLoading: {
    unassigned_conversation: false,
    assigned_conversation: false,
    resolved_conversation: false,
    average_first_response_time: false,
    average_resolution_time: false,
    average_response_time: false,
  },
  conversationSummaryFirstResponseDataTables: {
    conversations: [],
    pagination: '',
  },
  conversationSummaryResolutionDataTables: {
    conversations: [],
    pagination: '',
  },
  startTour: false,
})

export const actions = {
  getDivisionList({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${DIVISION_URL}/me`,
          { ...params.parameter, is_counted: params.parameter.offset === 1 },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe((res) => {
          commit('SET_DIVISION_LIST', res.data)
          resolve(res.data)
        })
    })
  },
  getTags({ commit, state }, params) {
    if (params.offset === 1) params.is_counted = true
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.tags}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            const moreThanLimit = Math.ceil(
              res?.meta?.pagination?.total / res?.meta?.pagination?.limit
            )
            commit('SET_TAGS', res.data)
            if (moreThanLimit) {
              for (let index = 1; index < moreThanLimit; index++) {
                params.offset = index + 1
                requests.whatsapp
                  .getService(`${WHATSAPP_URL.tags}`, params, {
                    Authorization: this.$auth.getToken('hub'),
                  })
                  .subscribe(
                    (res2) => {
                      commit('SET_TAGS', res2.data)
                    },
                    (err) => {
                      console.error(err)
                    }
                  )
              }
            }
            resolve(state.tag)
          },
          (err) => {
            console.error(err)
          }
        )
    })
  },
  postCreatePolicy({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${REPORTS_URL}/sla/configs`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  submitEditCreatePolicy({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(
          `${REPORTS_URL}/sla/configs/${params.sla_config_id}`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  submitDuplicateCreatePolicy({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${REPORTS_URL}/sla/check_duplicate`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getPolicyDetail({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${REPORTS_URL}/sla/configs`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe((res) => {
          resolve(res.data)
        })
    })
  },
  getSlaPolicyList({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${REPORTS_URL}/sla/configs`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_DATA_SLA', res?.data)
            resolve(res?.data)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
    })
  },
  setPolicyStatus({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(
          `${REPORTS_URL}/sla/configs/${params.sla_config_id}/is_active`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe((res) => {
          resolve(res?.status)
        })
    })
  },
  setChannelByDivision({ commit, state }, params) {
    commit('SET_CHANNEL_BY_DIVISION', params)
  },
  deletePolicyStatus({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .deleteService(
          `${REPORTS_URL}/sla/configs/${params.sla_config_id}`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
    })
  },
  getAllPerformanceTime({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      // Average first response
      commit('SET_LOADING_CONVERSATION_SUMMARY', {
        type: 'average_first_response_time',
        value: true,
      })
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/average-first-response-time`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_CONVERSATION_SUMMARY', {
              type: 'average_first_response_time',
              value: res?.data?.average_first_response_time,
            })
            commit('SET_LOADING_CONVERSATION_SUMMARY', {
              type: 'average_first_response_time',
              value: false,
            })
            resolve(res)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
      // Average resolution time
      commit('SET_LOADING_CONVERSATION_SUMMARY', {
        type: 'average_resolution_time',
        value: true,
      })
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/average-resolution-time`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_CONVERSATION_SUMMARY', {
              type: 'average_resolution_time',
              value: res?.data?.average_resolution_time,
            })
            commit('SET_LOADING_CONVERSATION_SUMMARY', {
              type: 'average_resolution_time',
              value: false,
            })
            resolve(res)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
      // Average response time
      commit('SET_LOADING_CONVERSATION_SUMMARY', {
        type: 'average_response_time',
        value: true,
      })
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/average-response-time`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_CONVERSATION_SUMMARY', {
              type: 'average_response_time',
              value: res?.data?.average_response_time,
            })
            commit('SET_LOADING_CONVERSATION_SUMMARY', {
              type: 'average_response_time',
              value: false,
            })
            resolve(res)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
    })
  },
  getAllConversationSummary({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      // Unassigned
      commit('SET_LOADING_CONVERSATION_SUMMARY', {
        type: 'unassigned_conversation',
        value: true,
      })
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/unassigned-conversation`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_CONVERSATION_SUMMARY', {
              type: 'unassigned_conversation',
              value: res?.data?.room_unassigned,
            })
            commit('SET_LOADING_CONVERSATION_SUMMARY', {
              type: 'unassigned_conversation',
              value: false,
            })
            resolve(res)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
      // Assigned
      commit('SET_LOADING_CONVERSATION_SUMMARY', {
        type: 'assigned_conversation',
        value: true,
      })
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/assigned-conversation`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_CONVERSATION_SUMMARY', {
              type: 'assigned_conversation',
              value: res?.data?.assigned_count,
            })
            commit('SET_LOADING_CONVERSATION_SUMMARY', {
              type: 'assigned_conversation',
              value: false,
            })
            resolve(res)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
      // Resolved
      commit('SET_LOADING_CONVERSATION_SUMMARY', {
        type: 'resolved_conversation',
        value: true,
      })
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/resolved-conversation`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_CONVERSATION_SUMMARY', {
              type: 'resolved_conversation',
              value: res?.data?.resolved_count,
            })
            commit('SET_LOADING_CONVERSATION_SUMMARY', {
              type: 'resolved_conversation',
              value: false,
            })
            resolve(res)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
    })
  },
  getEachConversationSummary({ commit, state }, params) {
    const endpointUrl = {
      assigned_conversation: 'assigned-conversation',
      resolved_conversation: 'resolved-conversation',
      average_first_response_time: 'average-first-response-time',
      average_resolution_time: 'average-resolution-time',
      average_response_time: 'average-response-time',
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/${endpointUrl[params.key]}`,
          params.filter,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            if (params.key === 'assigned_conversation') {
              commit('SET_CONVERSATION_SUMMARY', {
                type: params.key,
                value: res?.data?.assigned_count,
              })
            }
            if (params.key === 'resolved_conversation') {
              commit('SET_CONVERSATION_SUMMARY', {
                type: params.key,
                value: res?.data?.resolved_count,
              })
            }
            if (params.key === 'average_first_response_time') {
              commit('SET_CONVERSATION_SUMMARY', {
                type: params.key,
                value: res?.data?.average_first_response_time,
              })
            }
            if (params.key === 'average_resolution_time') {
              commit('SET_CONVERSATION_SUMMARY', {
                type: params.key,
                value: res?.data?.average_resolution_time,
              })
            }
            if (params.key === 'average_response_time') {
              commit('SET_CONVERSATION_SUMMARY', {
                type: params.key,
                value: res?.data?.average_response_time,
              })
            }
            resolve(res)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
    })
  },
  getConversationTableFirstResponse({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/first-response-time-sla`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_DATA_TABLE_CONVERSATION_SUMMARY', {
              type: 'first_response',
              value: res?.data,
            })
            resolve(res?.data)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
    })
  },
  getConversationTableResolution({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/conversation-summary/resolution-time-sla`,
          params,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_DATA_TABLE_CONVERSATION_SUMMARY', {
              type: 'resolution',
              value: res?.data,
            })
            resolve(res?.data)
          },
          (err) => {
            resolve(err)
            return Promise.reject(
              new Error('something bad when retrieving network', err)
            )
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_DIVISION_LIST(state, payload) {
    payload = payload.map((obj) => ({ ...obj, value: obj.name }))
    state.divisionList = payload
  },
  SET_TAGS(state, payload) {
    state.tag.list = state.tag.list.concat(payload)
  },
  SET_DATA_SLA(state, payload) {
    state.slaDataTables = payload
  },
  SET_CHANNEL_BY_DIVISION(state, payload) {
    state.channelByDivision = payload
  },
  SET_CONVERSATION_SUMMARY(state, payload) {
    state.conversationSummaryData[payload.type] = payload.value
  },
  SET_LOADING_CONVERSATION_SUMMARY(state, payload) {
    state.isLoading[payload.type] = payload.value
  },
  SET_DATA_TABLE_CONVERSATION_SUMMARY(state, payload) {
    /**
     * @param type - [ 'first_response', 'resolution']
     */
    if (payload.type === 'first_response')
      state.conversationSummaryFirstResponseDataTables = payload.value
    if (payload.type === 'resolution')
      state.conversationSummaryResolutionDataTables = payload.value
  },
  SET_TOUR_STATUS(state, payload) {
    state.startTour = payload
  },
}
