import { getField, updateField } from 'vuex-map-fields'
import requests from '~/requests'
import { BILLINGS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  loading: {
    order_history: false,
    detail_transaction: false,
  },
  response_order_history: [],
  response_detail_transaction: [],
  filter: {
    start_date: '',
    end_date: '',
    search_order: '',
    order_status: '',
  },
  pagination: {
    order_history: {
      current: 1,
      limit: 10,
      total_page: 0,
      total: 0,
      next: false,
      prev: true,
    },
  },
})

export const getters = {
  getField,
}

export const actions = {
  getData({ commit }, params) {
    commit('UPDATE_LOADING_ORDER_HISTORY', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${BILLINGS_URL}/orders`, params.payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_DATA_ORDER_HISTORY', res.data.response)
            commit('UPDATE_PAGINATION_PAGE', {
              type: 'order_history',
              current: res.data.pagination.offset,
              total: res.data.pagination.total,
              limit: params.payload.limit,
            })
            commit('UPDATE_LOADING_ORDER_HISTORY', false)
            resolve(true)
          },
          (err) => {
            commit('SET_DATA_ORDER_HISTORY', [])
            commit('UPDATE_LOADING_ORDER_HISTORY', false)
            reject(err)
          }
        )
    })
  },
  updateData({ commit, state }, params) {
    const payload = state.filter
    const merged = { ...payload, ...params.payload }

    if (merged.order_status === 'all') {
      delete merged.order_status
    }
    for (const propName in merged) {
      if (
        merged[propName] === null ||
        merged[propName] === undefined ||
        merged[propName] === ''
      ) {
        delete merged[propName]
      }
    }
    return new Promise((resolve, reject) => {
      commit('UPDATE_LOADING_ORDER_HISTORY', true)
      requests.whatsapp
        .getService(`${BILLINGS_URL}/orders`, merged, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_DATA_ORDER_HISTORY', res.data.response)
            commit('UPDATE_PAGINATION_PAGE', {
              type: 'order_history',
              current: res.data.pagination.offset,
              total: res.data.pagination.total,
              limit: params.payload.limit,
            })
            commit('UPDATE_LOADING_ORDER_HISTORY', false)
            commit('UPDATE_FILTER', merged)
            resolve(true)
          },
          (err) => {
            commit('SET_DATA_ORDER_HISTORY', [])
            commit('UPDATE_LOADING_ORDER_HISTORY', false)
            reject(err)
          }
        )
    })
  },
  getCountData({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/orders`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_PAGINATION_TOTAL', {
              type: 'order_history',
              total: res.data.pagination.total,
              limit: params.payload.limit,
            })
            resolve(true)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  updateButton({ commit }, params) {
    commit('UPDATE_PAGINATION_BUTTON', {
      type: params.type,
      action: params.action,
    })
  },
  updateChangePagination({ commit }, params) {
    commit('UPDATE_CHANGE_PAGINATION', { type: params.type, e: params.e })
  },
  downloadInvoice({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          Authorization: this.$auth.getToken('hub'),
        },
        responseType: 'blob',
      }
      this.$axios
        .get(`${BILLINGS_URL}/orders/downloads/${params.id}`, config)
        .then((res) => {
          const file = new Blob([res.data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = params.name + '.pdf'
          link.click()
        })
    })
  },
  getDetailTransactions({ commit }, params) {
    commit('UPDATE_LOADING_DETAIL_TRANSACTION', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/orders/${params.payload.id}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_DATA_DETAIL_TRANSACTION', res.data)
            commit('UPDATE_LOADING_DETAIL_TRANSACTION', false)
            resolve(true)
          },
          (err) => {
            commit('SET_DATA_ORDER_HISTORY', [])
            commit('UPDATE_LOADING_DETAIL_TRANSACTION', false)
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_LOADING_ORDER_HISTORY(state, payload) {
    state.loading.order_history = payload
  },
  UPDATE_LOADING_DETAIL_TRANSACTION(state, payload) {
    state.loading.detail_transaction = payload
  },
  SET_DATA_ORDER_HISTORY(state, payload) {
    state.response_order_history = payload
  },
  SET_DATA_DETAIL_TRANSACTION(state, payload) {
    state.response_detail_transaction = payload
  },
  UPDATE_FILTER(state, payload) {
    state.filter.start_date = payload.start_date ? payload.start_date : ''
    state.filter.end_date = payload.end_date ? payload.end_date : ''
    state.filter.order_status = payload.order_status
      ? payload.order_status
      : 'all'
    state.filter.search_order = payload.search_order ? payload.search_order : ''
  },
  UPDATE_PAGINATION_PAGE(state, payload) {
    state.pagination[payload.type].limit = payload.limit
    state.pagination[payload.type].total = payload.total
    state.pagination[payload.type].total_page = Math.ceil(
      payload.total / payload.limit
    )
    state.pagination[payload.type].current = payload.current
  },
  UPDATE_PAGINATION_BUTTON(state, payload) {
    if (payload.action === 'next') {
      if (state.pagination[payload.type].current)
        state.pagination[payload.type].prev = false
      if (
        state.pagination[payload.type].current >=
        state.pagination[payload.type].total_page
      )
        state.pagination[payload.type].next = true
    } else if (payload.action === 'initial') {
      state.pagination[payload.type].next =
        state.pagination[payload.type].current >=
        state.pagination[payload.type].total_page
      if (state.pagination[payload.type].current <= 1)
        state.pagination[payload.type].prev = true
    } else {
      if (state.pagination[payload.type].current <= 1)
        state.pagination[payload.type].prev = true
      if (state.pagination[payload.type].current)
        state.pagination[payload.type].next = false
    }
  },
  UPDATE_CHANGE_PAGINATION(state, payload) {
    state.pagination[payload.type].prev = payload.e <= 1
    state.pagination[payload.type].next =
      payload.e >= state.pagination[payload.type].total_page
  },
  UPDATE_PAGINATION_TOTAL(state, payload) {
    state.pagination[payload.type].total_page = Math.ceil(
      payload.total / payload.limit
    )
  },
}
