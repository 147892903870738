import { getField, updateField } from 'vuex-map-fields'
import requests from '../../../requests'
import {
  WABA_URL,
  WABA_LOGIN_URL,
  WABA_PROFILE_PICTURE_URL,
  WABA_SERVER_URL,
  WABA_PROFILE_ABOUT_URL,
  WABA_SETTINGS_URL,
} from '@/assets/variables/endpoints'

export const state = () => ({
  serverList: [],
  serverDetail: [
    {
      id: '',
      waba_id: '',
      phone_number: '',
      phone_number_id: '',
      certificates: '',
      token: null,
      api_server: null,
      tier: 0,
      display_name: '',
      quality_rating: '',
      quality_score: '',
      name_verification_status: '',
    },
  ],
  serverSettings: {
    unhealthyInterval: 0,
    heartbeatInterval: 0,
    webhookURL: '',
    maxConcurrentRequests: 0,
    callbackBackoffDelay: 0,
    maxCallbackBackoffDelay: 0,
    callbackPersist: false,
    autoDownload: {
      image: false,
      document: false,
      audio: false,
      video: false,
      voice: false,
    },
    sentStatus: false,
  },
  profileAbout: '',
  businessProfile: {
    address: '',
    description: '',
    email: '',
    vertical: {
      value: '',
      text: '',
    },
    websites: '',
  },
})

export const actions = {
  getServerList({ commit }) {
    const params = {
      business_id: process.env.WA_BUSINESS_ID,
    }
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(`${WABA_URL}/server`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('SET_SERVER_LIST', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
        }
      )
  },
  getServerDetailById({ commit }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(
        `${WABA_URL}/server/${params.id}`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('SET_SERVER_DETAIL', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
        }
      )
  },
  updateCredentials({ commit }, data) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const temp = {
      api_server: data.api_server,
      token: data.token,
    }
    requests.whatsapp
      .getService(WABA_LOGIN_URL, temp, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          requests.whatsapp
            .putService(`${WABA_SERVER_URL}/${data.id}`, data, {
              Authorization: this.$auth.getToken('hub'),
            })
            .subscribe(
              (res) => {
                requests.whatsapp
                  .getService(
                    `${WABA_URL}/server/${data.id}`,
                    {},
                    { Authorization: this.$auth.getToken('hub') }
                  )
                  .subscribe((res) => {
                    commit('SET_SERVER_DETAIL', res.data)
                    commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
                    commit(
                      'layouts/UPDATE_NOTIFICATION',
                      {
                        display: true,
                        type: 'success',
                        message: 'Successfully saved changes for WA server',
                        item: '',
                        callback: {
                          text: 'OK',
                          method: 'closeNotification',
                        },
                      },
                      { root: true }
                    )
                  })
              },
              (err) => {
                commit(
                  'layouts/UPDATE_NOTIFICATION',
                  {
                    display: true,
                    type: 'failed',
                    message: 'Error!',
                    item: err.error.messages[0],
                    callback: {
                      text: 'OK',
                      method: 'closeNotification',
                    },
                  },
                  { root: true }
                )
                commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
              }
            )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: 'API Server or token invalid\n' + err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  requestOtp({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(`${WABA_URL}/request_otp`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Requested!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  verifyOtp({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(`${WABA_URL}/verify_otp`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Your number is verified!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getSettings({ commit }, data) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .getService(WABA_SETTINGS_URL, data, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_SERVERSETTINGS', res.data.settings.application)
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  updateSettings({ commit }, data) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .putService(WABA_SETTINGS_URL, data, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully updated server settings',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getProfileAbout({ commit }, data) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .getService(WABA_PROFILE_ABOUT_URL, data, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          if (res.data.settings)
            commit('UPDATE_PROFILE_ABOUT', res.data.settings.profile.about.text)
          else commit('UPDATE_PROFILE_ABOUT', res.data.profile.about.text)
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  changeProfileAbout({ commit }, data) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .putService(WABA_PROFILE_ABOUT_URL, data, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully updated profile about',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getBusinessProfile({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .getService(`${WABA_URL}/settings/business/profile`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_BUSINESS_PROFILE', res.data.settings.business.profile)
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  changeBusinessProfile({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(`${WABA_URL}/settings/business/profile`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Your business profile has been successfully changed!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  changeProfilePicture({ commit }, data) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })

    const myHeaders = new Headers()
    myHeaders.append('Authorization', this.$auth.getToken('hub'))

    const formdata = new FormData()
    formdata.append('api_server', data.api_server)
    formdata.append('token', data.token)
    formdata.append('phone_number_id', data.phone_number_id)
    formdata.append('file', data.file)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }

    fetch(WABA_PROFILE_PICTURE_URL, requestOptions)
      .then((data) => data.json())
      .then((res) => {
        if (res.status === 'success') {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully updated profile picture',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        } else {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      })
      .catch((err) => {
        commit(
          'layouts/UPDATE_NOTIFICATION',
          {
            display: true,
            type: 'failed',
            message: 'Error!',
            item: err,
            callback: {
              text: 'OK',
              method: 'closeNotification',
            },
          },
          { root: true }
        )
        commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
      })
  },
  twoFactor({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(`${WABA_URL}/settings/account/two-step`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message:
                'You have successfully enable two factor authentication!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  shutDownServer({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(
        `${WABA_URL}/server/${params.id}`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
        }
      )
  },
}

export const mutations = {
  updateField,
  SET_SERVER_LIST(state, payload) {
    state.serverList = payload
  },
  SET_SERVER_DETAIL(state, payload) {
    state.serverDetail = []
    state.serverDetail.push(payload)
  },
  UPDATE_SERVERSETTINGS(state, payload) {
    const autoDownload = {
      image: false,
      document: false,
      audio: false,
      video: false,
      voice: false,
    }
    for (const key in autoDownload) {
      if (payload.media.auto_download.includes(key)) autoDownload[key] = true
    }
    state.serverSettings = {
      unhealthyInterval: payload.unhealthy_interval,
      heartbeatInterval: payload.heartbeat_interval,
      webhookURL: payload.webhooks.url,
      maxConcurrentRequests: payload.webhooks.max_concurrent_requests,
      callbackBackoffDelay: payload.callback_backoff_delay_ms,
      maxCallbackBackoffDelay: payload.max_callback_backoff_delay_ms,
      callbackPersist: payload.callback_persist,
      autoDownload,
      sentStatus: payload.sent_status,
    }
  },
  UPDATE_PROFILE_ABOUT(state, payload) {
    state.profileAbout = payload
  },
  UPDATE_BUSINESS_PROFILE(state, payload) {
    state.businessProfile = {
      address: payload.address ? payload.address : null,
      description: payload.description,
      email: payload.email ? payload.email : null,
      vertical: {
        value: payload.vertical,
        text: payload.vertical,
      },
      websites: payload.websites ? payload.websites[0] : null,
    }
  },
}

export const getters = {
  getField,
}
