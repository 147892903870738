/* eslint-disable no-console */
import { getField, updateField } from 'vuex-map-fields'
// import requests from '../../requests'
import { SERVICE_OAUTH_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  isLoading: false,
  response: [],
  responseChatbot: [],
})

export const getters = {
  getField,
}

export const actions = {
  getTokenAPI({ commit }) {
    commit('UPDATE_LOADING', true)
    return new Promise((resolve, reject) => {
      const headers = {
        authorization: this.$auth.getToken('hub'),
      }
      this.$axios
        .get(`${SERVICE_OAUTH_URL}/get_token`, headers)
        .then((res) => {
          if (res.data.response) {
            resolve(res.data.response)
          }
          commit('UPDATE_DATA', res.data.response)
          commit('UPDATE_LOADING', false)
        })
        .catch((e) => {
          const err = JSON.stringify(e)
          console.error(JSON.parse(err).name)
          reject(JSON.parse(err).name)
        })
    })
  },
  generateTokenAPI({ commit }) {
    commit('UPDATE_LOADING', true)
    return new Promise((resolve, reject) => {
      const headers = {
        authorization: this.$auth.getToken('hub'),
      }
      this.$axios
        .post(`${SERVICE_OAUTH_URL}/generate_token`, headers)
        .then((res) => {
          if (res.data.response) {
            resolve(res.data.response)
          }
          commit('UPDATE_DATA', res.data.response)
          commit('UPDATE_LOADING', false)
        })
        .catch((e) => {
          const err = JSON.stringify(e)
          console.error(JSON.parse(err).name)
          reject(JSON.parse(err).name)
        })
    })
  },
  generateTokenAPIChatbot({ commit }) {
    return new Promise((resolve, reject) => {
      const headers = {
        authorization: this.$auth.getToken('hub'),
      }
      this.$axios
        .post(
          `${process.env.HUB_SERVICE_URL}/api/core/v1/chatbots/auths/tokens`,
          headers
        )
        .then((res) => {
          if (res.data.data) {
            resolve(res.data.data)
          }
          commit('UPDATE_DATA_CHATBOT', res.data)
        })
        .catch((e) => {
          const err = JSON.stringify(e)
          console.error(JSON.parse(err).name)
          reject(JSON.parse(err).name)
        })
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_LOADING(state, payload) {
    state.isLoading = payload
  },
  UPDATE_DATA(state, payload) {
    if (payload) {
      state.response = payload
    }
  },
  UPDATE_DATA_CHATBOT(state, payload) {
    if (payload) {
      state.responseChatbot = payload
    }
  },
}
